import { FC } from "react";
import cn from "classnames";
import Styles from "./Status.module.css";
import { MoreInfo } from "../MoreInfo/MofeInfo";
import { StatusCode } from "../../types/status";
import { Spinner } from "../Spinner/Spinner";
import type { Undefined } from "../../types/index";
import { sep } from "../../utils/sep";

type StatusProps = {
  statusCode: Undefined<number>;
  code?: number;
  pending: boolean;
};

export const Status: FC<StatusProps> = ({ statusCode, pending, code }) => {
  const active = statusCode === StatusCode.active;

  const status = active ? "Активна" : "Не активна";

  const userCode = code ? sep(code, 4).join(" ") : "";

  const classes = cn(active && Styles.active, !active && Styles.notActive);

  if (pending) {
    return <Spinner />;
  }

  return (
    <div className={Styles.statusContainer}>
      <div className={Styles.numberCard}>
        <span>№ карты участника</span>
        <span>{userCode}</span>
      </div>
      <div className={Styles.status}>
        <span>Подписка МТС Premium</span>
        <span className={classes}>{status}</span>
        <span>{active ? "Привилегии доступны" : "Привилегии недоступны."}</span>
        {!active && <MoreInfo />}
      </div>
    </div>
  );
};
