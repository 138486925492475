import React, { useEffect, useState, FC } from "react";

export const MobileContext = React.createContext(false);

export const MobileProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  const onResize = () => {
    const element: HTMLElement | null = document.querySelector("#root");
    const width = element ? element?.clientWidth : window.innerWidth;
    if (width <= 767) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    onResize();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>
  );
};
