import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { RouterProvider } from "react-router5";
import { router } from "../src/router/router";
import { MobileProvider } from "./context/mobile";

ReactDOM.render(
  <StrictMode>
    <RouterProvider router={router}>
      <MobileProvider>
        <App />
      </MobileProvider>
    </RouterProvider>
  </StrictMode>,
  document.getElementById("root-pass")
);
