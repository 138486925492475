import { FC } from "react";
import { useRoute } from "react-router5";
import { Page } from "../components/Page/Page";
import { Status } from "../components/Status/Status";
import { useOnMountFetch } from "../hooks/useOnMountFetch";

type Response = {
  properties: {
    guid: string;
    code: number;
  };
  status: number;
};

const Guid: FC = () => {
  const {
    route: {
      params: { guid },
    },
  } = useRoute();

  const { data, pending } = useOnMountFetch<Response>(`/api/user/v1/${guid}`);

  return (
    <Page statusCode={data?.status}>
      <Status
        statusCode={data?.status}
        code={data?.properties?.code}
        pending={pending}
      />
    </Page>
  );
};

export { Guid };
