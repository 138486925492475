import { useState, useEffect } from "react";

const useOnMountFetch = <T,>(url: string) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<any>(null);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((err) => setError(err))
      .finally(() => setPending(false));
  }, [url]);

  return {
    data,
    pending,
    error,
  };
};

export { useOnMountFetch };
