import { FC, CSSProperties } from "react";
import { SpinnerIcon } from "./SpinnerIcon";
import cn from "classnames";
import Styles from "./Spinner.module.css";
import { SpinnerSizes, SpinnerColors } from ".";

export type SpinnerProps = {
  className?: string;
  color?: SpinnerColors;
  size?: SpinnerSizes;
  style?: CSSProperties;
};

const Spinner: FC<SpinnerProps> = ({
  color = SpinnerColors.primary,
  size = SpinnerSizes.l,
  className,
  style,
}) => {
  const classes = cn(
    Styles.spinner,
    Styles[color],
    size && Styles[size],
    className
  );

  return (
    <div className={classes} style={style}>
      <SpinnerIcon />
    </div>
  );
};

export { Spinner };
