export enum SpinnerSizes {
  s = "s",
  m = "m",
  l = "l",
}

export enum SpinnerColors {
  primary = "primary",
  white = "white",
  negative = "negative",
  mtsRed = "mtsRed",
}
