import createRouter from "router5";
import browserPlugin from "router5-plugin-browser";

const routes = [
  { name: "default", path: "/" },
  { name: "home", path: "/:guid" },
];

export const router = createRouter(routes, {
  defaultRoute: "default",
});

const BROWSER_CONFIG = {
  useHash: false,
  mergeState: true,
  base: process.env.PUBLIC_URL,
};

router.usePlugin(browserPlugin(BROWSER_CONFIG));

router.start();
