import { FC, useContext } from "react";
import { MobileContext } from "../../context/mobile";
import Styles from "./MoreInfo.module.css";
import logo from "../../images/telephone.svg";

const MoreInfo: FC = () => {
  const isMobile = useContext(MobileContext);

  console.log(isMobile);

  return (
    <div className={Styles.moreInfo}>
      {isMobile ? (
        <p>
          Оплатить подписку можно <br /> на сайте{" "}
          <a href="https://premium.mts.ru/">premium.mts.ru</a>
        </p>
      ) : (
        <p>
          Оплатить подписку можно на сайте
          <br />
          <a href="https://premium.mts.ru/">premium.mts.ru</a>
        </p>
      )}
      <div className={Styles.contacts}>
        <div className={Styles.logo}>
          <img alt="телефон" src={logo} />
        </div>
        <div className={Styles.support}>
          <span>Служба поддержки</span>
          <a href="tel:+88002500890">8 800 250-08-90</a>
        </div>
      </div>
    </div>
  );
};

export { MoreInfo };
