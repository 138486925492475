import { FC } from "react";
import { useRoute } from "react-router5";
import { Guid } from "../pages/Guid";
import { DefaultPage } from "../pages/DefaultPage";

const Screen: FC = () => {
  const { route } = useRoute();

  if (route.name === "home") {
    return <Guid />;
  }

  if (route.name === "default") {
    return <DefaultPage />;
  }

  return null;
};

export { Screen };
