import { FC } from "react";
import cn from "classnames";
import Styles from "./Page.module.css";
import premiumLogo from "../../images/premiumLogo.svg";
import mtsLogo from "../../images/mtsLogo.svg";
import { StatusCode } from "../../types/status";

type PageProps = {
  children?: React.ReactNode;
  statusCode?: number;
};

const Page: FC<PageProps> = ({ children, statusCode }) => {
  const active = statusCode === StatusCode.active;

  const classes = cn(
    active && Styles.active,
    !active && Styles.notActive,
    Styles.active
  );

  return (
    <main className={classes}>
      <header>
        <div className={Styles.premiumLogo}>
          <img src={premiumLogo} alt="Premium" />
        </div>
        <div className={Styles.mtsLogo}>
          <img src={mtsLogo} alt="МТС" />
        </div>
      </header>
      <div className={Styles.page}>{children}</div>
    </main>
  );
};

export { Page };
